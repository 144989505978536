// Imports
          import { Link } from 'react-router-dom'
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component112Page() {
const navigate = useNavigate()


//setContent const [content,setContent]= useState({})   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc0_1_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_1_onClick ")
                }  
              };

const bloc2_0_3_onClick = async (e) => {
                try{ 
                      navigate('/legal');
                }
                catch(e){
                  console.log("Error bloc2_0_3_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "flex flex-row items-center bg-zinc-700 safearea w-screen bg-secondary_color bg-opacity-50 justify-between"}     > <img  id='bloc0_0' src="/profiles/ploy28/user.png" className={ "rounded-xl m-6 h-16 w-16   object-cover"}     alt="undefined" />
          <Link    to="/home"><div id='bloc0_1'  className={ "p-2 font-bold text-xl text-secondary_color"}    onClick = { bloc0_1_onClick } >{`BMN TAXI'S`} </div></Link>
          <FontAwesomeIcon  id='bloc0_2'  icon={ fa.faTaxi}      className={ "h-16 w-16 m-6 text-secondary_color"}  /></div><div id='bloc1'  className={ "w-screen flex flex-col pt-10 text-slate-500"}     > <div id='bloc1_0'  className={ "p-2 text-4xl font-semibold pb-10"}     >{`Mentions légales`} </div>
          <div id='bloc1_1'       > <div id='bloc1_1_0x0'  className={ "p-2 flex gap-1 flex-col w-full bg-white"}     > <div id='bloc1_1_0x0_0'  className={ "w-full p-2 bg-white font-bold flex flex-col items-start text-xl"}     >{`Mentions Légales`} </div>
              <div id='bloc1_1_0x0_1x0'  className={ "p-2 flex gap-1 w-full flex-col border-t"}     >{`[object Object]`} <div id='bloc1_1_0x0_1x0_0'  className={ "w-full p-2 bg-white flex flex-col items-start text-lg font-bold"}     >{`Editeur`} </div>
                <div id='bloc1_1_0x0_1x0_1x0'  className={ "w-full p-2 bg-white flex flex-col items-start"}     >{`App is You, SAS au capital de 4 470 204 € ayant son siège social au 10 rue de Penthièvre à Paris (75008),   immatriculée sous le numéro 852 895 929 au RCS de Paris.`} </div></div>
      <div id='bloc1_1_0x0_1x1'  className={ "p-2 flex gap-1 w-full flex-col border-t"}     >{`[object Object]`} <div id='bloc1_1_0x0_1x1_0'  className={ "w-full p-2 bg-white flex flex-col items-start text-lg font-bold"}     >{`Directeur de la publication `} </div>
                <div id='bloc1_1_0x0_1x1_1x0'  className={ "w-full p-2 bg-white flex flex-col items-start"}     >{`Monsieur x  Adresse de courrier électronique et numéro de téléphone  hey@toto.io – +33 1 89 77 66 35  Hébergeur du site OVH SAS est une filiale de la société OVH Groupe SA, société immatriculée au RCS de Lille sous le numéro 537 407 926 sise 2, rue Kellermann, 59100 Roubaix.  Déclaration CNIL Conformément à la loi N°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, la société x a fait l’objet d’une déclaration auprès de la CNIL sous le numéro d’enregistrement 2027779. Vous pouvez à tout moment vous opposer à ce qu'on vous transmette des informations, en nous contactant par courrier ou par e-mail. Vous avez la possibilité d’introduire une réclamation auprès de l’autorité de contrôle (CNIL). `} </div></div>
      <div id='bloc1_1_0x0_1x2'  className={ "p-2 flex gap-1 w-full flex-col border-t"}     >{`[object Object]`} <div id='bloc1_1_0x0_1x2_0'  className={ "w-full p-2 bg-white flex flex-col items-start text-lg font-bold"}     >{`Cookies `} </div>
                <div id='bloc1_1_0x0_1x2_1x0'  className={ "w-full p-2 bg-white flex flex-col items-start"}     >{`Nous avons choisi Axeptio pour protéger votre vie privée et garantir votre confidentialité sur ce site. Les cookies de ce site ont pour objet la réalisation de statistiques et d’audience : compter le nombre de visites sur ce site et améliorer l’expérience utilisateur. Nous vous informons que vous pouvez, à tout moment, vous opposer à l’enregistrement de cookies et changer les paramètres en configurant votre navigateur de manière spécifique. Nous vous rappelons que ce paramétrage est susceptible de modifier vos conditions d’accès à nos services nécessitant l’utilisation de cookies. Les cookies sont stockés sur votre ordinateur.`} </div></div>
      <div id='bloc1_1_0x0_1x3'  className={ "p-2 flex gap-1 w-full flex-col border-t"}     >{`[object Object]`} <div id='bloc1_1_0x0_1x3_0'  className={ "w-full p-2 bg-white flex flex-col items-start text-lg font-bold"}     >{`Protection des données à caractère personnel`} </div>
                <div id='bloc1_1_0x0_1x3_1x0'  className={ "w-full p-2 bg-white flex flex-col items-start"}     >{`Un délégué à la protection des données à caractère personnel a été désigné par x, il est joignable à l’adresse suivante : dpo@toto.io`} </div></div>
      <div id='bloc1_1_0x0_1x4'  className={ "p-2 flex gap-1 w-full flex-col border-t"}     >{`[object Object]`} <div id='bloc1_1_0x0_1x4_0'  className={ "w-full p-2 bg-white flex flex-col items-start text-lg font-bold"}     >{`Crédits photographiques et droit d’auteur`} </div>
                <div id='bloc1_1_0x0_1x4_1x0'  className={ "w-full p-2 bg-white flex flex-col items-start"}     >{`Sauf précision contraire, la mise en page, les visuels et les contenus présents sur ce site (y compris les textes, photographies, dessins, schémas, marques et logos) sont protégés par le droit de la propriété intellectuelle et appartiennent à x.`} </div></div>
      <div id='bloc1_1_0x0_1x5'  className={ "p-2 flex gap-1 w-full flex-col border-t"}     >{`[object Object]`} <div id='bloc1_1_0x0_1x5_0'  className={ "w-full p-2 bg-white flex flex-col items-start text-lg font-bold"}     >{`Responsabilité et modification`} </div>
                <div id='bloc1_1_0x0_1x5_1x0'  className={ "w-full p-2 bg-white flex flex-col items-start"}     >{`L’ensemble des informations présentes sur le site, sous quelque forme que ce soit, a un caractère strictement informatif. x ne garantit pas que lesdites informations sont exactes ni complètes. Par ailleurs, x ne saurait être tenu responsable des dommages directs ou indirects subis par un internaute lors de l’utilisation du présent site internet ou de l’impossibilité d’y accéder. Enfin, x se réserve le droit de modifier les présentes mentions légales à tout moment, sans avoir à en avertir les utilisateurs. Il est de la seule responsabilité des utilisateurs d’en vérifier régulièrement le contenu.`} </div></div></div></div></div><div id='bloc2'  className={ "w-full bg-black bg-primary_color flex flex-row justify-center text-secondary_color"}     > <div id='bloc2_0'  className={ "p-2 h grid grids-cols-1 md:grid-cols-2 gap-1 lg:grid-cols-3 pb-40"}     > <div id='bloc2_0_0'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_0_0'  className={ "text-xl font-bold"}     >{`Gares`} </div>
              <div id='bloc2_0_0_1'  className={ "whitespace-pre-line text-left"}     >{`Gare SNCF de LYON-PART-DIEU
      Gare SNCF de LYON-PERRACHE
      Gare SNCF de LYON-VAISE
      Gare routière de LYON-perrache
      Gare SNCF de BOURG EN BRESSE
      Gare de Genève Cornavin `} </div></div>
            <div id='bloc2_0_1'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_1_0'  className={ "font-bold text-xl"}     >{`Hôpitaux`} </div>
              <div id='bloc2_0_1_1'  className={ "whitespace-pre-line text-left"}     >{`Hôpital Édouard herriot 
      Centre Léon Bérard 
      Hôpital de la croix rousse 
      Hôpital saint-Joseph saint Luc 
      Hôpital privé jean mermoz
      Hôpital privé natecia 
      Hôpital Louis Pradel 
      Clinique du parc
      Hôpital neurologique pierre Wertheimer
      Hôpital Lyon Sud
      Hôpital Fleyriat
      Clinique Convert `} </div>
              <div id='bloc2_0_1_2'       > </div></div>
            <div id='bloc2_0_2'  className={ " flex flex-col items-start p-4"}     > <div id='bloc2_0_2_0'  className={ "font-bold text-xl"}     >{`Aéroports `} </div>
              <div id='bloc2_0_2_1'  className={ "whitespace-pre-line text-left"}     >{`Aéroport de Lyon saint éxupery
      Aéroport international de Genève 
      Aéroport de Dole-Jura`} </div></div>
            <Link    to="/legal"><div id='bloc2_0_3'  className={ "font-bold text-xl text-left p-4 underline"}    onClick = { bloc2_0_3_onClick } >{`Mentions légales`} </div></Link></div></div></>
  
}

export default Component112Page;
